export default function ApplicationLogo({ className }) {
    return (
        <svg width="109px" height="37px" viewBox="0 0 109 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
            <title>logo_biolectric</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketch:type="MSPage">
                <g id="logo_biolectric" sketch:type="MSLayerGroup" fill="#33261D">
                    <g id="Page-1" sketch:type="MSShapeGroup">
                        <g id="Biolectric_BruinOpWit_RGB">
                            <g id="Group">
                                <path d="M10.4866992,33.1766302 C9.83796278,33.5424704 9.10478974,33.6185503 7.44051107,33.6185503 L3.28973843,33.6185503 L3.28973843,27.9262426 L7.44051107,27.9262426 C8.99206137,27.9262426 9.80978068,28.078074 10.4866992,28.5005089 C11.1637274,28.9226154 11.6431519,29.7686893 11.6431519,30.8385148 C11.6431519,31.9082308 11.1637274,32.7541953 10.4866992,33.1766302 L10.4866992,33.1766302 L10.4866992,33.1766302 Z M3.28973843,20.4824556 L7.2996006,20.4824556 C8.65332797,20.4824556 9.21762777,20.5552515 9.75330684,20.8085592 C10.4866992,21.1465947 11.05067,21.929068 11.05067,22.9141657 C11.05067,23.8435444 10.6559014,24.5557396 10.0072746,24.9777367 C9.27399195,25.4283047 8.14572133,25.4084911 7.2996006,25.4084911 L3.28973843,25.4084911 L3.28973843,20.4824556 L3.28973843,20.4824556 Z M11.8405362,26.5406036 C13.2790292,25.9210178 14.3788984,24.4568905 14.3788984,22.5141716 C14.3788984,20.4871627 13.3917575,18.9380888 11.8687183,18.1780562 C10.9662334,17.7275976 9.95069115,17.4173669 7.55345875,17.4173669 L0,17.4173669 L0,36.6836391 L7.97651911,36.6836391 C10.4585171,36.6836391 11.4740594,36.3703432 12.404507,35.8635089 C14.0120926,34.9906154 15.0276348,33.3562663 15.0276348,31.216068 C15.0276348,28.8226716 13.5893612,27.2443698 11.8405362,26.5406036 L11.8405362,26.5406036 L11.8405362,26.5406036 Z" id="Fill-3"></path>
                                <path d="M16.668008,23.3286095 L19.9577465,23.3286095 L19.9577465,36.6836391 L16.668008,36.6836391 L16.668008,23.3286095 L16.668008,23.3286095 Z" id="Fill-4"></path>
                                <path d="M16.668008,17.4173669 L19.9577465,17.4173669 L19.9577465,20.810858 L16.668008,20.810858 L16.668008,17.4173669 L16.668008,17.4173669 Z" id="Fill-5"></path>
                                <path d="M28.2461328,34.2549941 C26.1873048,34.2549941 24.7208491,32.6502012 24.7208491,29.9752544 C24.7208491,27.3005266 26.130831,25.6959527 28.2461328,25.6959527 C30.3614346,25.6959527 31.7715262,27.2723935 31.7715262,29.9752544 C31.7715262,32.6219586 30.3050704,34.2549941 28.2461328,34.2549941 L28.2461328,34.2549941 L28.2461328,34.2549941 Z M28.2461328,22.9647396 C24.156659,22.9647396 21.5335312,25.7238669 21.5335312,30.0316302 C21.5335312,34.3112604 24.1283672,37.0141213 28.2461328,37.0141213 C32.3638984,37.0141213 34.9587344,34.3112604 34.9587344,30.0316302 C34.9587344,25.7238669 32.335826,22.9647396 28.2461328,22.9647396 L28.2461328,22.9647396 L28.2461328,22.9647396 Z" id="Fill-6"></path>
                                <path d="M36.6257545,17.4173669 L39.805835,17.4173669 L39.805835,36.6836391 L36.6257545,36.6836391 L36.6257545,17.4173669 L36.6257545,17.4173669 Z" id="Fill-7"></path>
                                <path d="M48.0643944,25.6549024 C49.8693642,25.6549024 51.1385453,26.6126331 51.2233109,28.5830473 L44.6798018,28.5830473 C44.7644577,26.9410355 46.2309135,25.6549024 48.0643944,25.6549024 L48.0643944,25.6549024 L48.0643944,25.6549024 Z M48.3180332,34.2171183 C45.9489829,34.2171183 44.8208219,32.7428107 44.5952555,30.7723964 L54.3821177,30.7723964 C54.3821177,30.662929 54.4101901,30.218929 54.4101901,29.9937544 C54.4101901,25.8830325 52.3512525,22.9456923 48.0643944,22.9456923 C44.3132153,22.9456923 41.4644115,25.7144527 41.4644115,29.8531982 C41.4644115,34.1892041 44.1719759,37.0094142 48.1489406,37.0094142 C52.0693219,37.0094142 53.8744014,34.5623787 54.4665543,33.6332189 L52.2385241,31.926074 C51.8154638,32.6015976 50.7435573,34.2171183 48.3180332,34.2171183 L48.3180332,34.2171183 L48.3180332,34.2171183 Z" id="Fill-8"></path>
                                <path d="M62.683992,25.7238669 C64.3198692,25.7238669 65.3350825,26.7092929 65.814507,28.145287 L68.4937797,26.737645 C67.6760604,24.6260178 65.7297414,22.9647396 62.5994457,22.9647396 C58.9046308,22.9647396 55.9993531,25.5268254 55.9993531,30.0316302 C55.9993531,34.5364349 58.7070272,37.0141213 62.5994457,37.0141213 C66.0400734,37.0141213 67.8734447,34.7617189 68.4937797,33.3256154 L65.899163,31.9464349 C65.4197384,33.1568166 64.5172535,34.226642 62.683992,34.226642 C60.5684708,34.226642 59.1867807,32.6502012 59.1867807,29.9752544 C59.1867807,27.3005266 60.540508,25.7238669 62.683992,25.7238669" id="Fill-9"></path>
                                <path d="M81.2565392,25.2456036 L81.2565392,23.3286095 L78.0764588,23.3286095 L78.0764588,36.6836391 L81.2565392,36.6836391 L81.2565392,30.6513254 C81.2565392,29.4684201 81.3998622,28.680145 81.65383,28.0045118 C82.1332545,26.7940207 83.1109648,26.1744349 84.831169,26.1744349 C85.1133189,26.1744349 85.3138833,26.2026775 85.7525151,26.2308107 L85.7525151,22.9647396 C85.6428571,22.936716 85.380007,22.936716 85.2110241,22.936716 C82.6161881,22.936716 81.5855131,24.5694231 81.2565392,25.2456036" id="Fill-10"></path>
                                <path d="M87.3973843,23.3286095 L90.6871227,23.3286095 L90.6871227,36.6836391 L87.3973843,36.6836391 L87.3973843,23.3286095 L87.3973843,23.3286095 Z" id="Fill-11"></path>
                                <path d="M98.9827465,25.7238669 C100.618843,25.7238669 101.634276,26.7092929 102.113481,28.145287 L104.792425,26.737645 C103.975473,24.6260178 102.029044,22.9647396 98.8984195,22.9647396 C95.2036046,22.9647396 92.2984366,25.5268254 92.2984366,30.0316302 C92.2984366,34.5364349 95.006001,37.0141213 98.8984195,37.0141213 C102.339376,37.0141213 104.172857,34.7617189 104.792425,33.3256154 L102.197918,31.9464349 C101.718712,33.1568166 100.816227,34.226642 98.9827465,34.226642 C96.8675543,34.226642 95.4857545,32.6502012 95.4857545,29.9752544 C95.4857545,27.3005266 96.8394819,25.7238669 98.9827465,25.7238669" id="Fill-12"></path>
                                <path d="M73.1418511,17.8976006 L73.1418511,17.4173669 L69.8521127,17.4173669 L69.8521127,33.1568166 C69.8521127,34.452145 69.9157143,35.2121775 70.451503,35.859787 C71.185005,36.7607041 72.4731569,37.0141213 73.7706298,37.0141213 C74.7295885,37.0141213 75.6639839,36.929503 76.1026157,36.7889467 L76.1026157,34.2549941 C75.9929577,34.3112604 75.339835,34.3674172 74.7755352,34.3674172 C74.2115644,34.3674172 73.7192002,34.2830178 73.4652324,34.0015769 C73.2113742,33.7482692 73.1418511,33.3821006 73.1418511,32.6219586 L73.1418511,25.7368935 L76.1026157,25.7368935 L76.1026157,23.3286095 L73.1418511,23.3286095 L73.1418511,17.8976006" id="Fill-13"></path>
                                <path d="M33.781995,4.44580178 C30.0512123,1.59526923 25.1139728,0.0380946746 19.879999,0.0380946746 C14.6461348,0.0380946746 9.70900503,1.58892012 5.97822233,4.43945266 C2.12308753,7.38511243 0,11.3966568 0,15.5564201 L3.13161167,15.5564201 C3.13161167,8.76943787 10.644826,3.12628107 19.879999,3.12628107 C29.1152817,3.12628107 36.6287153,8.76943787 36.6287153,15.5564201 L39.760327,15.5564201 C39.760327,11.3966568 37.6372394,7.39135207 33.781995,4.44580178" id="Fill-14"></path>
                                <path d="M104.394366,15.5564201 L104.394366,12.3818639 L41.1087907,12.3818639 C41.3983974,13.367071 41.5482998,14.4617456 41.5482998,15.5564201 L104.394366,15.5564201" id="Fill-15"></path>
                                <path d="M107.36171,14.1988047 C107.514135,14.1988047 107.589799,14.1893905 107.638048,14.1654172 C107.714809,14.127213 107.753189,14.0606568 107.753189,13.9655296 C107.753189,13.8701834 107.714809,13.7984822 107.638048,13.765642 C107.589799,13.7414497 107.518521,13.7366331 107.36171,13.7366331 L106.984487,13.7366331 L106.984487,14.1988047 L107.36171,14.1988047 L107.36171,14.1988047 Z M106.984487,15.1894852 L106.612746,15.1894852 L106.612746,13.3843669 L107.408863,13.3843669 C107.57664,13.3843669 107.70494,13.4034142 107.795956,13.4509231 C107.976891,13.5366361 108.129316,13.7127692 108.129316,13.9655296 C108.129316,14.2751036 107.94838,14.4465296 107.70494,14.5227189 L108.191821,15.1894852 L107.719195,15.1894852 L107.266308,14.5463639 L106.984487,14.5463639 L106.984487,15.1894852 L106.984487,15.1894852 Z M107.357324,13.0412959 C106.659899,13.0412959 106.125865,13.6033018 106.125865,14.2941509 C106.125865,14.9847811 106.659899,15.5470059 107.357324,15.5470059 C108.053652,15.5470059 108.587686,14.9847811 108.587686,14.2941509 C108.587686,13.6033018 108.053652,13.0412959 107.357324,13.0412959 L107.357324,13.0412959 L107.357324,13.0412959 Z M107.357324,15.9231361 C106.430714,15.9231361 105.73,15.2132396 105.73,14.2941509 C105.73,13.3748432 106.430714,12.6650562 107.357324,12.6650562 C108.28174,12.6650562 108.983551,13.3748432 108.983551,14.2941509 C108.983551,15.2132396 108.28174,15.9231361 107.357324,15.9231361 L107.357324,15.9231361 L107.357324,15.9231361 Z" id="Fill-16"></path>
                                <path d="M87.3973843,20.810858 L90.6871227,20.810858 L90.6871227,17.4173669 L87.3973843,17.4173669 L87.3973843,20.810858 L87.3973843,20.810858 Z" id="Fill-17"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
